@use '@density/dust/dist/scss/utils' as dust;

.selectfield {
  position: relative;
  box-sizing: border-box;
  border: 1px solid dust.$gray-200;
  background-color: dust.$white;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  font-variant-numeric: tabular-nums slashed-zero;
}
.selectfield.light {
  background-color: #fff;
  border-color: dust.$gray-200;
}
.selectfield.dark {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.selectfield.focused {
  border-color: dust.$blue-400;
  box-shadow: 0px 0px 0px 2px dust.$blue-200;
}

.selectfield.error {
  border-color: dust.$red-400;
}

.selectfield.disabled {
  &,
  & input {
    cursor: not-allowed;
  }

  &.light {
    background-color: dust.$gray-100;
  }
  &.light input {
    color: dust.$gray-300;
  }

  &.dark {
    background-color: rgba(255, 255, 255, 0.05);
  }
  &.dark input {
    color: rgba(255, 255, 255, 0.2);
  }
}

.selectfield .left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;
  flex-shrink: 1;
}

.selectfield .leading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: dust.$blue-700;
}
.selectfield .leading.icon {
  width: 20px;
  height: 20px;
  padding-right: 4px;
}
.selectfield .leading.prefix {
  // Don't set a width, because wide text strings will probably overflow (and that's fine!)
  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  user-select: none;
}

.selectfield select {
  background-color: transparent;

  cursor: pointer;

  border: 0px;
  height: 100%;
  width: 100%;
  outline: none;

  padding-left: 4px;
  padding-right: 4px;

  flex-grow: 1;
  flex-shrink: 1;
}
.selectfield.light select {
  color: dust.$gray-900;
}
.selectfield.dark select {
  color: #fff;
}
.selectfield.light select::placeholder {
  color: dust.$gray-300;
}
.selectfield.dark select::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.selectfield .right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectfield .trailing {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: dust.$blue-700;
}
.selectfield .trailing.icon {
  width: 20px;
  height: 20px;
  padding-left: 4px;
}
.selectfield .trailing.suffix {
  // Don't set a width, because wide text strings will probably overflow (and that's fine!)
  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  user-select: none;
}

.selectfield .helperText {
  position: absolute;
  bottom: -16px;
  line-height: 16px;
  font-size: 10px;
  color: dust.$gray-400;
  user-select: none;
  pointer-events: none;
}

.selectfield.sizeSmall {
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;

  &,
  & input {
    font-size: 12px;
  }

  .leading.icon,
  .trailing.icon {
    width: 16px;
    height: 16px;
  }
  .leading.prefix,
  .trailing.suffix {
    height: 16px;
  }
}

.selectfield.sizeMedium {
  height: 32px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;

  &,
  & input {
    font-size: 14px;
  }
  & input {
    transform: translateY(-1px);
  }

  .leading.icon,
  .trailing.icon {
    width: 20px;
    height: 20px;
  }
  .leading.icon,
  .trailing.suffix {
    height: 20px;
  }
}

.selectfield.sizeLarge {
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;

  &,
  & input {
    font-size: 16px;
  }
  & input {
    transform: translateY(-1px);
  }

  .leading.icon,
  .trailing.icon {
    width: 24px;
    height: 24px;
  }
  .leading.prefix,
  .trailing.suffix {
    height: 24px;
  }
}
