@use '@density/dust/dist/scss/utils' as dust;
// Keybindings Modal
.settingsMenuRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
  height: 32px;
  user-select: none;
}
.settingsMenuRowLabel {
  flex-grow: 1;
  flex-shrink: 1;
  width: 0px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.settingsMenuRowLabelText {
  margin-left: dust.$space-2;
  margin-right: dust.$space-2;
  white-space: nowrap;
}
.keyBindButton {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
