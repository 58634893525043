@import '@density/dust/dist/tokens/dust.tokens';
@import '@density/dust/dist/scss/utils';

.tabBar {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: flex-end;
  padding-left: 4px;
  padding-right: 4px;
  background-color: $white;
}

.tab {
  display: flex;
  align-items: center;

  margin-left: 4px;
  margin-right: 4px;
  padding-left: 16px;
  padding-right: 16px;
  height: 32px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid $gray-300;
  border-bottom: 0px;
  @include text-preset(4);
  font-weight: bold;
  cursor: pointer;
  background-color: $gray-200;
  color: $gray-500;
}
.tab.active {
  color: $blue-400;
  background-color: $white;
  border-color: $gray-300;
}
