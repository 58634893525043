@use '@density/dust/dist/scss/utils' as dust;
@import '../editor/styles.module.scss';

.container {
  @extend .controlPanel;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  user-select: none;
  z-index: 1;
  background-color: dust.$white;
  margin-right: 6px;
  margin-left: 6px;

  &.small {
    width: 64px;
  }
}

.containerExtended {
  @extend .container;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  bottom: 35px;
  width: fit-content;
  transform: translateX(-35%);
  box-sizing: border-box;

  &.closed {
    border: none;
  }
}

.menu {
  display: none;
  padding: 12px 0;
  border-bottom: 1px solid dust.$gray-200;

  &.open {
    display: block;
    width: 100%;
  }
}

.entry {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  white-space: nowrap;

  .title {
    flex: 1;
    margin-left: 8px;
    padding-right: 10px;
  }
}

.menuToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  cursor: pointer;

  &.small {
    width: 64px;
  }

  .title {
    flex: 1;
    line-height: 40px;
    margin-left: 8px;
    font-weight: bold;
  }
}
