@use '@density/dust/dist/scss/utils' as dust;

.contentMessage {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;

  .contentMessageHeader {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 4px;
    padding-top: 4px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .contentMessageHeaderContent {
      flex-grow: 2;
    }
    .contentMessageLoadingIndicator {
      flex-grow: 0;
    }
  }

  .contentMessageHeaderIcon {
    margin-right: 8px;
    display: flex;
    align-items: bottom;
  }

  .contentMessageBody {
    color: dust.$gray-400;
    padding-bottom: 4px;
    padding-top: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
