.fullFrame {
  border: 0.75px solid #262626;
  position: relative;
  margin: 10px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.topFrame {
  border-top: 0.75px solid #262626;
  position: relative;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.halfFrame {
  border-top: 0.75px solid #262626;
  border-bottom: 0.75px solid #262626;
  position: relative;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.title {
  position: absolute;
  top: -0.7em;
  left: 20px;
  background: #fff;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
