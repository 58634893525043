@use '@density/dust/dist/scss/utils' as dust;

@keyframes fadeInGreen {
  from {
    background-color: none;
  }
  to {
    background-color: dust.$green-200;
  }
}

@keyframes fadeOutGreen {
  from {
    background-color: dust.$green-200;
  }
  to {
    background-color: none;
  }
}

@keyframes fadeInRed {
  from {
    background-color: none;
  }
  to {
    background-color: dust.$red-200;
  }
}
@keyframes fadeOutRed {
  from {
    background-color: dust.$red-200;
  }
  to {
    background-color: none;
  }
}

.fadeGreen {
  animation: fadeInGreen 0.75s linear, fadeOutGreen 0.75s ease 0.75s;
}

.fadeRed {
  animation: fadeInRed 0.75s linear, fadeOutRed 0.75s ease 0.75s;
}

.entryDashContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  top: 20%;
  left: 80%;
  width: fit-content;
  font-size: 0.75rem;
  box-shadow: 3px 5px 5px rgba(34, 42, 46, 0.5);
  border: 1px solid dust.$gray-200;
  border-radius: 10px;
}

.entryDashSpaceHeader {
  cursor: pointer;
  user-select: none;
  font-size: 0.85rem;
  padding: 0.2rem;
  margin: 0.2rem 0.2rem;
  width: 16rem;
  border-bottom: 1px solid dust.$blue-700;
  color: dust.$blue-700;
  background-color: white;
}

.entryDashSpaceHeader:hover {
  background-color: dust.$gray-100;
}

.entryDashTableContainer {
  background-color: none;
  font-size: 0.85rem;
  width: 18rem;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
}

.entryDashPopoutTableContainer {
  position: absolute;
  top: 20%;
  left: 70%;
  height: 0;
}

.entryDashHeaderContainer {
  padding: 0.5rem 0.25rem;
}

.entryDashHeader {
  margin: 0.1rem 0.2rem;
}

.entryDashTable {
  background-color: white;
  border-radius: 5px;
}

.entryDashTableActions {
  display: flex;
  align-items: center;
}

.entryDashTableTitle {
  display: flex;
  justify-content: space-between;
}

.entryDashTablePopoutContainer {
  margin: 0 0.4rem;
  cursor: pointer;
}

.entryDashTablePopout {
  background: none;
  cursor: pointer;
  border: none;
}

.entryDashTableData {
  display: grid;
  margin: 0;
  padding: 0 1rem 0.25rem 1rem;
  list-style-type: none;
  grid-template-columns: 1fr auto;
}

.entryDashTableLabel {
  width: 65%;
  white-space: normal;
  color: dust.$blue-400;
  font-weight: bold;
  cursor: pointer;
}
