@use '@density/dust/dist/scss/utils' as dust;

.header {
  height: dust.$space-9;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: dust.$space-1;
  padding-right: dust.$space-4;

  color: dust.$white;
  background-color: dust.$gray-800;
  border-bottom: 1px solid dust.$gray-700;
}

.headerTitle {
  @include dust.text-preset(6);
  @include dust.text-unselectable;
  margin-left: dust.$space-4;
}

.wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  background-color: dust.$gray-800;
  color: dust.$white;
  padding-top: dust.$space-4;
  padding-left: dust.$space-4;
  padding-right: dust.$space-4;
}

.buildingAddressField {
  display: flex;
  margin-left: 15px;
  font-size: 14px;
  margin-top: auto;
  align-items: center;
  color: dust.$gray-200;
}

.buildingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid dust.$gray-600;
  height: dust.$space-8;
  margin-bottom: dust.$space-3;
}

.buildingHeaderLeft {
  display: flex;
  align-items: center;
  width: 0px;
  padding-right: dust.$space-3;
  flex-grow: 1;
  flex-shrink: 1;
}
.buildingHeaderRight {
  display: flex;
  align-items: center;
}

.buildingName {
  display: flex;
  align-items: center;
  @include dust.text-preset(6);
  @include dust.text-unselectable;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: dust.$space-2;
}

.buildingExpiresAt {
  display: flex;
  align-items: center;
  justify-content: center;

  color: dust.$white;

  margin-left: dust.$space-3;
  margin-right: dust.$space-3;

  @include dust.text-preset(3);
  @include dust.text-unselectable;
  font-style: italic;
}

.buildingFloorplanList {
  display: flex;
  gap: dust.$space-4;
  flex-wrap: wrap;
  margin-bottom: dust.$space-5;
}

.floorplanItem {
  color: dust.$white;
  text-decoration: none;
  text-align: left;
  cursor: pointer;

  display: flex;
  align-items: center;
  background-color: dust.$gray-700;
  border-radius: dust.$radius-200;
  min-width: 300px;
  width: 30%;
  flex-grow: 0;
  flex-shrink: 1;
  height: dust.$space-9;
  padding-left: dust.$space-3 + dust.$space-1;
  padding-right: dust.$space-3 + dust.$space-1;
}
.floorplanItem:focus {
  background-color: dust.$gray-600;
}
.floorplanItem.creationPlaceholder {
  background-color: transparent;
  border: 2px dashed dust.$gray-600;
  opacity: 0.35;
  transition: opacity 100ms ease-in-out;
}
.floorplanItem.creationPlaceholder:hover {
  opacity: 1;
}
.floorplanItem.creationPlaceholder:focus {
  opacity: 1;
  background-color: dust.$gray-700;
}
.floorplanItem.deleting {
  opacity: 0.35;
}

.floorplanItemThumbnail {
  display: flex;
  align-items: center;
  justify-content: center;

  width: dust.$space-7;
  height: dust.$space-7;

  color: dust.$gray-200;
  background-color: dust.$gray-500;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: dust.$radius-200;
  filter: grayscale(1);
}

.floorplanItemDetails {
  display: flex;
  flex-direction: column;
  padding-left: dust.$space-3;
  gap: dust.$space-1;
  width: 0px;
  flex-grow: 1;
  flex-shrink: 1;
}

.floorplanItemDetailsName {
  @include dust.text-preset(5);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.floorplanItemDetailsLineTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include dust.text-preset(3);
  @include dust.text-unselectable;
  color: dust.$gray-400;
}
.floorplanItemDetailsLineTwoTag {
  display: flex;
  align-items: center;

  height: 14px;
  padding-left: dust.$space-2;
  padding-right: dust.$space-2;

  background-color: dust.$yellow-200;
  color: dust.$gray-700;
  border-radius: dust.$radius-200;

  @include dust.text-preset(1);
  font-weight: dust.$font-weight-bold;
  @include dust.text-unselectable;
}
.floorplanItemDetailsLineTwoTag.dimmed {
  background-color: dust.$gray-500;
  color: dust.$gray-700;
}

.floorplanItemAction {
  opacity: 0.35;
  transition: opacity 100ms ease-in-out;
  margin-left: dust.$space-3;
  cursor: default;
}
.floorplanItemAction:focus {
  opacity: 1;
}
.floorplanItem:hover .floorplanItemAction {
  opacity: 1;
}

.buildingFloorplanListEmpty {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include dust.text-preset(6);
  @include dust.text-unselectable;
  color: dust.$gray-500;
}

.organizationAppBar {
  display: flex;
  align-items: center;
  height: dust.$space-6;
  gap: dust.$space-3;
  margin-bottom: dust.$space-3;
}
.organizationAppBarSearch {
  flex-grow: 1;
  flex-shrink: 1;
}
.organizationList {
  max-height: 300px;
  overflow-y: auto;
}
.organizationListItem {
  color: dust.$white;
  background-color: transparent;
  padding-left: dust.$space-3;
  padding-right: dust.$space-3;
  margin-bottom: dust.$space-1;
  @include dust.text-preset(4);
  @include dust.text-unselectable;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: dust.$space-6;
  border-radius: dust.$radius-200;
  width: 100%;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.organizationListItem.active {
  background-color: dust.$gray-600;
}
.organizationListItemAction {
  margin-left: dust.$space-2;
}
.organizationEmpty {
  height: 64px;
}
.organizationLoading {
  height: 300px;
}
.organizationError {
  height: 300px;
  color: dust.$red-400;
}

.organizationCreationAppBar {
  display: flex;
  align-items: center;
  gap: dust.$space-3;
  @include dust.text-preset(4);
  @include dust.text-unselectable;
}
.organizationCreationActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: dust.$space-4;
}

.organizationCreationExistingOrganizationHint {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: dust.$space-3;
  margin-left: dust.$space-3 + dust.$space-2;
  height: 20px;
  @include dust.text-unselectable;
  white-space: nowrap;
}

.organizationCreationExistingOrganizationHintText {
  flex-grow: 1;
  flex-shrink: 1;
  width: 0px;
  margin-right: dust.$space-3;

  display: flex;
  align-items: center;
}

.organizationCreationExistingOrganizationHintText strong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: dust.$space-2;
}

.timezoneText {
  color: dust.$gray-400;
  height: 100%;
  margin-left: 0.5em;
  font-size: 12px;
}
