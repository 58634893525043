@use '@density/dust/dist/scss/utils' as dust;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header {
  height: dust.$space-9;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: dust.$space-4;
  padding-left: dust.$space-4;
  padding-right: dust.$space-4;

  color: dust.$white;
  background-color: dust.$gray-800;
  border-bottom: 1px solid dust.$gray-700;
}

.wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  background-color: dust.$gray-800;
  color: dust.$white;
  padding-top: dust.$space-4;
  padding-left: dust.$space-4;
  padding-right: dust.$space-4;
}

.dataSection {
  display: flex;
  flex-direction: column;
  margin-bottom: dust.$space-4;
  border: 1px solid dust.$gray-700;
  padding: dust.$space-4;
}

.dataRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: dust.$space-2;
  padding-bottom: dust.$space-2;
  height: dust.$space-8;
  user-select: none;
}

.dataHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: dust.$space-2;
  padding-bottom: dust.$space-2;
  height: dust.$space-8;
  user-select: none;
}

.dataKey {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: bold;
}

.infoSectionContainer {
  display: grid;
  grid-gap: 10px; /* Space between items */
  padding: 10px; /* Optional: padding around the grid */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.infoSectionTitle {
  display: flex;
  color: inherit;
  text-decoration: none;
  font-size: 24px;
  background-color: dust.$gray-800;
  padding: dust.$space-4;
  border-radius: 8px;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.infoSectionCard {
  background-color: dust.$gray-700;
  padding: dust.$space-4;
  border-radius: 5px;
  box-shadow: -3px -5px 10px rgba(0, 0, 0, 0.284);
  max-width: 500px;
  height: fit-content;
}

.infoSectionDataRow {
  margin-bottom: dust.$space-4;
  font-size: 18px;
}

.infoSectionImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: dust.$space-4;
  margin-top: dust.$space-4;
  width: 250px;
  height: 250px;
  user-select: none;

  color: dust.$gray-200;
  background-color: dust.$gray-500;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: dust.$radius-200;
  filter: grayscale(1);
}
.tooltipContents {
  font-size: 16px;
}

.metaDataTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: dust.$space-4;
  gap: dust.$space-4;
  cursor: pointer;
  user-select: none;
}

.buildingInfoDataContainer {
  font-size: 24px;
  gap: dust.$space-4;
  margin-bottom: dust.$space-4;
  padding: dust.$space-4;
}
.buildingInfoDataHeader {
  font-size: 24px;
  margin-bottom: dust.$space-4;
  user-select: none;
  cursor: pointer;
}

.buildingInfoExpandableDataHeader {
  font-size: 24px;
  gap: dust.$space-4;
  margin-bottom: dust.$space-4;
  user-select: none;
  cursor: pointer;
}

.buildingInfoExpandableDataHeader:hover {
  color: dust.$yellow-200;
}

.buildingDataContainer {
  display: flex;
  flex-direction: column;
}

.entityInfoContainer {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  display: grid;
  grid-gap: 10px; /* Space between items */
  padding: 10px; /* Optional: padding around the grid */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  color: dust.$white;
  padding: 1em;
  background-color: dust.$gray-900;
  border-radius: dust.$radius-200;
  box-shadow: -3px -4px 10px rgba(0, 0, 0, 0.284);
}

.infoDataContainer {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  padding: dust.$space-4;
}

.infoDataContainer > * {
  margin: dust.$space-2 0;
  user-select: none;
  cursor: pointer;
}

.infoDataContainer > *:hover {
  color: dust.$yellow-200;
}

.infoData {
  margin: dust.$space-3;
  user-select: none;
  cursor: pointer;
}

.infoData:hover {
  color: dust.$yellow-200;
}

.dataTableContainer {
  max-height: 75vh;
  width: fit-content;
  overflow-y: scroll;
}

.dataTable {
  width: 100%;
}

.dataTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: dust.$space-2 dust.$space-4;
  border-radius: 5px;
}

.dataTableTitle {
  font-size: 24px;
}

.dataTableHeaders {
  position: sticky;
  color: white;
  padding: 0;
  top: 0;
  background-color: dust.$blue-500; /* Ensure text is readable over scrolled content */
  z-index: 1; /* Make sure headers are above other content */
}

.dataTableHeaderContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataTableHeaderButton {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.dataTableRow:nth-child(odd) {
  background-color: dust.$gray-200;
}

.dataTableRow:hover {
  background-color: dust.$gray-700;
  color: dust.$white;
}

.dataTableFooter {
  position: sticky;
  bottom: 0; /* Ensure text is readable over scrolled content */
  background-color: dust.$gray-200;
  z-index: 1; /* Make sure headers are above other content */
}

.loadingBarContainer {
  width: 35%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 10px 0;
}

.loadingBarProgress {
  height: 20px;
  background-color: dust.$blue-400;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  transition: width 0.2s ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.loadingPercentage {
  display: flex;
  background-color: dust.$gray-800;
  width: 100%;
  justify-content: center;
}

.infoShowAllButton {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: none;
  color: dust.$white;
  margin: dust.$space-4 0;
  padding: dust.$space-2;
  border-radius: dust.$radius-200;
  cursor: pointer;
}

.buildingName {
  cursor: pointer;
}

input[type='time']::-webkit-calendar-picker-indicator {
  color: white;
}

.newBuildingModalForm {
  padding: dust.$space-3;
}

.spaceMetaRow {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: space-between;
}

.formError {
  margin: 0;
  font-size: 12px;
  color: dust.$red-500;
}

.metadataButtonRow {
  display: flex;
  justify-content: space-between;
  padding: 0 dust.$space-4 dust.$space-4 dust.$space-4;
}

.spaceMetaRowCopy {
  cursor: pointer;
  font-size: 12px;
}

.spaceMetaRowCopy:hover {
  color: dust.$yellow-500;
}

.aocDataRow {
  display: flex;
  flex-direction: row;
  gap: dust.$space-3;
  align-items: center;
  height: dust.$space-8;
  user-select: none;
  margin-bottom: dust.$space-2;
  background-color: dust.$gray-700;
  padding: dust.$space-4;
  border-radius: 10px;
  width: fit-content;
  box-shadow: -3px -2.5px 5px rgba(0, 0, 0, 0.284);
}
